import React from 'react';
import './Calibracion.css';
import obra_calibracion from './img/obra_calibracion.webp';
import calibracion from './img/calibracion_generador.webp';
import { Helmet } from 'react-helmet';

function Calibracion() {
  return (
    <>
      <Helmet>
        <title>Interphase - Ingeniería Eléctrica y Montajes</title>
        <meta name="description" content="La prueba de protecciones a motores principales y generadores, consiste en someter a cada sensor de estos motores, a una prueba de medición controlada, (temperatura, presión o contacto) con instrumentos certificados, de manera que se pueda verificar su correcta activación o medición. Permitiendo entregar al cliente, información relevante, del estado de los sensores que protegen a el motor, frente a un mal funcionamiento o falta de mantención." />
      </Helmet>
    <div className='calibracion-container'>
          <div className="portada-container-calibracion">
              <img src={obra_calibracion} alt="calibrando generador" className="image-style" />
              <p className="text-overlay"><span style={{ color: 'red' }}>C</span>alibración de <span style={{ color: 'red' }}>S</span>ensores</p>
          </div>
        <div className="descripcion-calibracion">
          <div className='texto-calibracion'>
            <h2 className='titulo-calibracion'>
              <span className="highlighted-letter">E</span>N QUÉ CONSISTE?
            </h2>
            <h2 className='titulo-calibracion'>
              <span className="highlighted-letter">P</span>rueba de Protecciones para <span className="highlighted-letter">M</span>otores Principales y <span className="highlighted-letter">G</span>eneradores: <span className="highlighted-letter">G</span>arantizando la Seguridad y <span className="highlighted-letter">E</span>ficiencia
            </h2>
            <p>
              Nuestra prueba de protecciones es un proceso exhaustivo que asegura la correcta funcionalidad de los sensores de temperatura, presión o RPM en motores principales y generadores.
            </p>
            <p>
              Utilizando instrumentos certificados, sometemos cada sensor a una medición controlada para verificar su activación precisa y confiable.
              Con este servicio, nuestros clientes reciben información valiosa sobre el estado de los sensores que protegen su motor, permitiéndoles:
            </p>
            <ul>
              <li>Identificar posibles fallos o mal funcionamientos.</li>
              <li>Programar mantenimientos preventivos y reducir tiempos de inactividad.</li>
              <li>Garantizar la seguridad y eficiencia de sus operaciones.</li>
            </ul>
            <p>
              Nuestra prueba de protecciones es una inversión en la seguridad y productividad de su equipo. Confíe en nosotros para asegurarse de que sus motores y generadores estén protegidos y funcionando óptimamente.
            </p>
          </div>
          <div className="image-container-calibracion">
            <img src={calibracion} alt="Panel de control" />
          </div>
        </div>

    </div>
    </>
  )
}

export default Calibracion
