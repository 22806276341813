import React from 'react'
import './MantenimientoG.css';
import Mantenimiento from './img/generador_averiado.webp';
import calibrando from './img/calibracion_generador.webp';
import { Helmet } from 'react-helmet';

function MantenimientoG() {
  return (
     <>
          <Helmet>
              <title>Interphase - Ingeniería Eléctrica y Montajes</title>
              <meta name="description" content="Con más de 10 años de experiencia en el sector, nos enorgullece ofrecer
                    servicios de mantenimiento eléctrico de generadores de primera calidad
                    para garantizar el funcionamiento confiable de tus equipos en todo momento." />
          </Helmet>
    <div className='mantenimiento-container'>
        <div className="portada-container">
              <img src={Mantenimiento} alt="generador en mal estado" className="image-style" />
              <p className="text-overlay"><span style={{ color: 'red' }}>M</span>antenimiento de <span style={{ color: 'red' }}>G</span>eneradores</p>
        </div>
        <div className="company-info">
            <div className="box">
                <h2><span className="highlight">E</span>mpresa <span className="highlight">I</span>nterphase</h2>
                <p>Especialistas en Mantenimiento de Generadores Eléctricos.</p>
                <p>
                    Con más de 10 años de experiencia en el sector, nos enorgullece ofrecer
                    servicios de mantenimiento eléctrico de generadores de primera calidad
                    para garantizar el funcionamiento confiable de tus equipos en todo momento.
                </p>
            </div>

            <div className="box">
                <h2><span className="highlight">N</span>uestros <span className="highlight">S</span>ervicios</h2>
                <ul>
                    <li>Inspección y diagnóstico completos.</li>
                    <li>Mantenimiento preventivo y correctivo.</li>
                    <li>Pruebas de carga y rendimiento.</li>
                    <li>Reparaciones y piezas de repuesto de alta calidad.</li>
                </ul>
            </div>

            <div className="box">
                <h2><span className="highlight">P</span>or qué <span className="highlight">E</span>legirnos?</h2>
                <ul>
                    <li>Equipo técnico altamente capacitado y certificado.</li>
                    <li>Compromiso con la calidad y la satisfacción del cliente.</li>
                    <li>Precios competitivos y transparentes.</li>
                    <li>Atención personalizada y soluciones adaptadas a tus necesidades.</li>
                </ul>
            </div>
        </div>
        <div className="cta-container">
            <p>
                ¡Confía en nosotros para mantener tus generadores funcionando sin problemas y evita costosas averías!
                <br />
                  Contáctanos hoy mismo para obtener una <b>evaluación gratuita</b>.
                <br />
                ¡Garantizamos tu tranquilidad energética!
            </p>
        </div>
        <div className="imagen-portada-calibrar">
              <img src={calibrando} alt="Calibracion de generador" className="image-style" />
        </div>
    </div>
    </>
  )
}

export default MantenimientoG
