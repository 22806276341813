import React from "react";
import "./Footer.css"; // Crear un archivo CSS para los estilos

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Hace que el scroll sea suave
    });
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="contact-info">
          <p className="ultimate-gear direccion">Tu confianza,<br /> nuestra misión.</p>
          <p className="contacto">
            <span role="img" aria-label="email">
              <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" fill="black"/>
                <path d="M20 4H4C2.897 4 2 4.897 2 6V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V6C22 4.897 21.103 4 20 4ZM20 6V6.511L12 12.734L4 6.512V6H20ZM4 18V9.044L11.386 14.789C11.5611 14.9265 11.7773 15.0013 12 15.0013C12.2227 15.0013 12.4389 14.9265 12.614 14.789L20 9.044L20.002 18H4Z" fill="#FC0505"/>
              </svg>
            </span> contacto@interphase.cl
          </p>
        </div>
        <div className="services">
          <h3 className="ultimate-gear titulo-servicios">SERVICIOS</h3>
          <ul className="ultimate-gear">
            <li>Sistemas de control y automatización</li>
            <li>Diseño e ingeniería</li>
            <li>Control y automatización de generadores</li>
            <li>Monitoreo de procesos</li>
          </ul>
        </div>
        <div className="subir-icon" onClick={scrollToTop} style={{ cursor: 'pointer' }}>
          <svg width="50" height="55" viewBox="0 0 50 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="55" fill="black" />
            <path d="M6.25006 43.5417H43.7501C44.1297 43.5404 44.5019 43.4252 44.8265 43.2086C45.1511 42.9919 45.4158 42.682 45.5921 42.3121C45.7685 41.9423 45.8498 41.5265 45.8274 41.1096C45.8049 40.6927 45.6795 40.2904 45.4646 39.9461L26.7146 10.1544C25.9376 8.91923 24.0667 8.91923 23.2876 10.1544L4.53756 39.9461C4.32052 40.2897 4.19325 40.6922 4.16957 41.1098C4.14588 41.5274 4.22669 41.9442 4.40321 42.3148C4.57973 42.6854 4.84521 42.9957 5.17081 43.2121C5.49642 43.4284 5.86968 43.5424 6.25006 43.5417Z" fill="#FC0505" />
          </svg>
          <span>SUBIR</span>
        </div>
      </div>
      <div className="bottom-side ultimate-gear-slant">
        <div className="copyright">
          <p>© 2024 Interphase. Todos los derechos <br />reservados.</p>
        </div>
        <div className="social-media ultimate-gear">
          <p>INTERPHASE <span style={{ color: '#FC0505' }}>ELECTRIC</span> LIMITADA</p>
        </div>
        <div className="electrica-montajes ultimate-gear">
          <p>INGENIERÍA <span style={{ color: '#FC0505' }}>ELÉCTRICA</span> Y MONTAJES</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

