import React from 'react';
import portada from './img/portada_2.webp';
import inteligen from './img/inteligen_diseño.webp';
import Monitoreo from './img/monitoreo_online.webp'
import './AutomatizacionG.css';
import { Helmet } from 'react-helmet';

const AutomatizacionG = () => {
  return (
    <>
    <Helmet>
        <title>Interphase - Ingeniería Eléctrica y Montajes</title>
        <meta name="description" content="Servicio de Integración de controladores INTELIGEN para Generadores Diesel. Control y monitoreo confiable para sus embarcaciones y sistemas de energía marina con los controladores certificados." />
    </Helmet>
    <div className='container-auto'>
        <div className="portada-container-automatizacion">
          <img src={portada} alt="automatizacion de generadores" className="image-style" />
          <p className="text-overlay"><span style={{ color: 'red' }}>A</span>utomatización <span style={{ color: 'red' }}>G</span>eneradores</p>
        </div>

      <div className="inteligen-container">
          <h1><span className="highlighted">D</span>escripción y <span className="highlighted">D</span>iseño <br /> del <span className="highlighted">S</span>istema</h1>
          <p>Optimice la eficiencia de su embarcación y desbloquee el potencial de sus <b>generadores Diesel</b> con nuestro servicio de integración de <b>controladores inteligentes.</b>
          </p>
          <p>Nuestra solución avanzada ofrece un control y monitoreo confiable para sus generadores,
            garantizando una gestión eficiente de la energía y una mayor seguridad en sus embarcaciones.
          </p>
        <div className="image-container">
          <img src={inteligen} alt="Panel de control" />
        </div>
      </div>
      
      <div className='section-grid'>
        <h1><span style={{ color: 'red' }}>C</span>aracterísticas del <span style={{ color: 'red' }}>S</span>istema</h1>
        <div className="grid-container">
          <div className="grid-item">
              <span className="arrow-icon">❯</span>
            <p>
                Diseñado para aplicaciones de<br />
                <b>alta exigencia,</b> nuestro sistema garantiza
                una supervisión en tiempo real para una respuesta<br />
                rápida ante cualquier anomalía.
            </p>
          </div>
          <div className="grid-item">
              <span className="arrow-icon">❯</span><p>
                <b>Protecciones:</b> sensores de temperatura Coolante, presión de lubricante, 
                niveles de estanques, presión combustible, 
                y muchos más parámetros de protección <b>definidos por el fabricante y usuario.</b>
            </p>
          </div>
          <div className="grid-item">
              <span className="arrow-icon">❯</span>
            <p>
                <b>Modo Standby:</b> listos para entrar en acción
                cuando sea necesario.<br />
                <b>Modo isla:</b> autonomía y eficiencia
                en la generación de energía.
            </p>
          </div>
          <div className="grid-item">
              <span className="arrow-icon">❯</span>
            <p>
                <b>Sistemas de sincronismo en paralelo:</b> gestión eficiente de la energía para reducir
                costos y aumentar la autonomía.
            </p>
          </div>
        </div>
      </div>
        <div class="ventajas-section">
          <h2>VENTAJAS</h2>
          <div class="ventajas-grid">
            <div class="ventaja-item">
              <span class="ventaja-icon">✔</span>
              <p>Mejorar la eficiencia y reducir costos de mantenimiento.</p>
            </div>
            <div class="ventaja-item">
              <span class="ventaja-icon">✔</span>
              <p>Aumentar la seguridad y confiabilidad de sus sistemas de energía.</p>
            </div>
            <div class="ventaja-item">
              <span class="ventaja-icon">✔</span>
              <p>Optimizar el rendimiento de sus generadores Diesel.</p>
            </div>
          </div>
          <p className='confia'>
            Confía en nuestra experiencia y tecnología para mantener tus operaciones marítimas seguras y eficientes.
          </p>
        </div>

      <div className='container-img-generadores'>
        <img src={Monitoreo} alt="diseño-inteligen" className="image-style" />
        <p className="text-2-overlay"><span style={{ color: 'red' }}>S</span>upervisión <span style={{ color: 'red' }}>R</span>emota <br />
        <span style={{ color: 'red' }}>M</span>odelo</p>
      </div>
    </div>
    </>
  )
}

export default AutomatizacionG