import React from 'react';
import './MonitoreoO.css';
import websupervisor from './img/websupervisor.webp';
import modelo_barco from './img/modelo_barco.webp';
import remoto from './img/remoto_2.webp';
import { Helmet } from 'react-helmet';

function MonitoreoO() {
  return (
    <>
          <Helmet>
              <title>Interphase - Ingeniería Eléctrica y Montajes</title>
              <meta name="description" content="WebSupervisor es un sistema basado en una nube para la supervisión y gestión remota de Motores Diesel y equipo eléctrico en el área Industrial, Naviera, y transporte." />
          </Helmet>
    <div className='container-monitoreo-online'>
          <div className="websupervisor-container">
              <div className="header">
                  <img src={websupervisor} alt="WebSupervisor Logo" className="logo" />
              </div>
              <div className="content">
                  <div className="text-section">
                      <h2>WebSupervisor</h2>
                      <p>
                          WebSupervisor es un sistema basado en una nube para la supervisión y gestión remota de Motores Diesel y equipo eléctrico en el área industrial, naviera, y de transporte.
                      </p>
                      <h3>WebSupervisor le brinda:</h3>
                      <ul>
                          <li>Descripción general del estado de los parámetros y niveles del motor.</li>
                          <li>Ubicación y otros datos y alertas importantes de todos sus dispositivos.</li>
                          <li>Todo en una pantalla.</li>
                          <li>
                              Si se produce una alarma, se le notificará por correo electrónico. Se puede acceder fácilmente al registro de datos detallado, las tendencias históricas y la administración de dispositivos.
                          </li>
                          <li>
                              El sistema también ofrece potentes herramientas de análisis y generación de informes como el Analizador de alarmas o mapas de tendencias y calor que le ofrecen un valor añadido adicional y le ayudan a:
                              <ul>
                                  <li className="highlight">Optimizar los ingresos para la flota de grupos electrógenos.</li>
                                  <li className="highlight">Reducir el costo de mantenimiento.</li>
                              </ul>
                          </li>
                      </ul>
                  </div>
                  <div className="image-section">
                      <img src={remoto} alt="WebSupervisor online" className="dashboard-image" />
                  </div>
              </div>
              <div className="ventajas-container">
                  <div className="image-section-2">
                      <img src={modelo_barco} alt="Diagrama de la Aplicación" className="ventajas-image" />
                  </div>
                  <div className="text-section-2">
                      <h2><span className="highlight">Ventajas de su Aplicación</span></h2>
                      <ol>
                          <li>
                              IntelliGenNT es un controlador disponible a nivel mundial, lo que lo hace fácil de reemplazar, no es un sistema cerrado que requiera del manejo y configuración de solo 1 persona. Puede verificar servicio técnico en diferentes países en el siguiente link: <a href="https://www.comap-control.com/contact-us">https://www.comap-control.com/contact-us</a>
                          </li>
                          <li>
                              Eliminar Paneles existentes de pésima calidad que provocan fallas repetitivas y falta de visualización de parámetros.
                          </li>
                          <li>
                              Al implementar el sistema ComAp, se logrará eliminar la gran cantidad de puntos de fallas: decenas de Relés de control innecesarios, permitirá eliminar PLC descontinuados que impiden su acceso para configuraciones o mejoras, eliminar gran cantidad de cableado; al ser un sistema de comunicación CAN solo bastan 2 cables para comunicar y controlar los 3 generadores, ya sea en modo STANDBY o en paralelismo.
                          </li>
                      </ol>
                  </div>
              </div>
          </div>
    </div>
    </>
  )
}

export default MonitoreoO
