// MonitoreoAlarmas.js
import React from 'react';
import MonitoreoA from './img/portada_1.webp';
import './MonitoreoA.css';
import MonitoreoB from './img/panel.webp';
import MonitoreoC from './img/panel_ejemplo.webp';
import { Helmet } from 'react-helmet';


function MonitoreoAlarmas() {
    return (
        <>
        <Helmet>
            <title>Interphase - Ingeniería Eléctrica y Montajes</title>
                <meta name="description" content="Sistema de alarma y monitoreo
Sistema de alarma de extensión
Control de válvulas, bombas y ventiladores
Medición de niveles tanques de agua
Medicion de niveles de tanques de Combustibles, aceites, residuos.
Monitoreo de RPM, temperaturas y presiones en Motores Principales Diesel.
Monitoreo de grupos electrógenos y sus variables eléctricas.
Monitoreo de sistema de propulsión.
" />
        </Helmet>
        <div className='monitoreo-a-container'>
            <div className="portada-container">
                <img src={MonitoreoA} alt="monitoreo alarmas" className="image-style" />
                <p className="text-overlay"><span style={{ color: 'red' }}>S</span>istema de <span style={{ color: 'red' }}>M</span>onitoreo de <span style={{ color: 'red' }}>B</span>uques</p>
            </div>
                <div className='descripcion-container-1'>
                    <h2 style={{ color: 'black' }}>
                        La solución integral para un monitoreo confiable
                    </h2>
                    <p>
                        Maximice la seguridad y eficiencia de su embarcación con nuestra solución integral de monitoreo.
                        Nuestro sistema avanzado captura información precisa a través de sensores especializados,
                        monitoreando en tiempo real los sistemas críticos de su nave.
                    </p>
                    <h3><span className="title-highlight ">E</span>STRUCTURA DEL <span className="title-highlight ">S</span>ISTEMA</h3>
                    <ul>
                        <li>Sensores digitales y analógicos para una captación de datos precisa.</li>
                        <li>Unidad central CPU para un procesamiento eficiente de la información.</li>
                        <li>Pantallas HMI (Interfaz Hombre-Máquina) personalizadas para una visualización clara e intuitiva.</li>
                        <li>Recibir alertas y notificaciones instantáneas en caso de anomalías o alarmas.</li>
                        <li>Optimizar tus operaciones y reducir costos gracias a la eficiencia del sistema.</li>
                    </ul>
                    <p>
                        Confía en nuestra experiencia y tecnología para mantener tus operaciones marítimas seguras y eficientes.
                    </p>
                </div>
            <div className="estaciones-container">
                    <div className="estaciones-content">
                        <h1 className="title">
                            <span className="title-highlight underline">Flexibilidad</span> y <span className="underline">Escalabilidad</span>
                        </h1>
                        <ul className="estaciones-list">
                            <li>Permite la ampliación de estaciones de trabajo para múltiples<br /> operadores.</li>
                            <li>Cada estación de trabajo está equipada con pantallas<br /> táctiles personalizadas de alta calidad.</li>
                            <li>Disponibles en tamaños de 7 a 21 pulgadas, lo que garantiza<br /> una visualización clara y precisa de la información.</li>
                            <li>Trackpad y Trackball para una navegación intuitiva.</li>
                            <li>La redundancia es clave en nuestro sistema, con enlace Ethernet<br /> garantizando la continuidad de la supervisión en todo momento.</li>
                            <li>Interfaz amigable y personalizable.</li>
                            <li>La captación de información precisa a través de sensores<br /> digitales y analógicos, presentada en <br />pantallas HMI, permite una toma de decisiones<br /> informada y rápida.</li>
                        </ul>
                    </div>
                <div className="estaciones-image">
                    <img src={MonitoreoB} alt="Estaciones Screen" />
                </div>
            </div>
            <div className="panel-container">
                <h1><span className="highlighted">P</span>ersonalización del <span className="highlighted">P</span>anel</h1>
                <ul className="instructions">
                    <li>La presentación de datos e información se diseña en colaboración con el cliente.</li>
                    <li>Los colores, las fuentes y los símbolos se pueden seleccionar libremente.</li>
                    <li>Se facilita un deslizamiento directo a la página de <b>resumen de alarmas</b> y a la <b>página principal</b> de información general.</li>
                </ul>
                <div className="image-container">
                    <img src={MonitoreoC} alt="Panel de control" />
                </div>
            </div>
        </div>
        </>
    )
}

export default MonitoreoAlarmas;