import React from 'react'
import luces from './img/luces_navegacion.webp'
import panel from './img/panel-luces.webp'
import luz_portada from './img/luz_barco_portada.webp'
import './Luces.css'
import { Helmet } from 'react-helmet';

function Luces() {
  return (
    <>
    <Helmet>
        <title>Interphase - Ingeniería Eléctrica y Montajes</title>
        <meta name="description" content="PANEL LUCES NAVEGACION FB-NAV-001.
        Para garantizar las funciones de control y monitorización de las luces de navegación del buque, INTERPHASE ofrece una variada gama de paneles. Pueden monitorizar y controlar hasta 48 luces de navegación en 24 Vdc o 230 Vac, con una potencia que va desde los 20 W hasta los 100 W.
" />
    </Helmet>
    <div className='luces-container'>
        <div className="portada-container-luces">
          <img src={luces} alt="Luces de navegacion" className="image-style" />
          <p className="text-overlay"><span style={{ color: 'red' }}>P</span>anel <span style={{ color: 'red' }}>L</span>uces de <span style={{ color: 'red' }}>N</span>avegación</p>
        </div>
        <div className="panel-luces-navegacion">
          <div className='text-container'>
            <p className='texto-panel'>
              Para garantizar las funciones de control y monitorización de las luces de navegación del buque, INTERPHASE ofrece una variada gama de<br />
              paneles. Pueden monitorizar y controlar hasta 48 luces de navegación en 24 Vdc o 230 Vac, con una potencia que va desde los 20 W hasta los<br />
              100 W.
            </p>
          </div>
          <div className='container-interfaz-caracteristicas'>
            <ol>
              <li>
                Cuenta con una interfaz personalizable para <br />
                adaptarse a embarcaciones marinas<br />
                de cualquier tipo y tamaño.
              </li>
              <li>
                El FB-NAV-001 consta de un panel Interfaz<br />
                Touch 7” y un panel principal. El panel<br />
                touch permite al usuario monitorear el estado/fallas<br />
                de las lámparas, así como encender/apagar<br />
                las luces de navegación.
              </li>
              <li>
                El panel de control contiene los módulos de<br /> monitoreo, fusibles y conectores.
              </li>
            </ol>
            <div className="imagen-panel">
              <img src={panel} alt="Panel Luces Navegacion" />
            </div>
          </div>
        </div>
      <div className="caracteristicas-container">
        <h2>
          <span className="highlighted-letter">C</span>aracterísticas del sistema
        </h2>
        <ul>
          <li>Operación altamente confiable.</li>
          <li>Panel touch totalmente personalizable con opción de montaje empotrado/sobrepuesto.</li>
          <li>Capaz de diseñar para cumplir con los requisitos de retorno seguro al puerto (SRTP).</li>
          <li>Compatible con lámparas de tipo filamento o led DC de 15-80 Watts.</li>
          <li>Monitoreo de falla de lámpara con alarma de audio y visual.</li>
          <li>Sistema de luces de dos niveles para luces de navegación críticas.</li>
          <li>Fuente de alimentación dual (Principal/Emergencia) con indicadores LED de estado y alarma.</li>
          <li>Módulos de monitoreo fácilmente reemplazables.</li>
        </ul>
      </div>
      <div className="funcionamiento-container">
        <h2>
          <span className="highlighted-letter">F</span>uncionamiento del sistema
        </h2>
        <p>
          Cuando el usuario enciende una luz de navegación usando los interruptores en el panel Touch, el sistema suministra energía al<br />
           módulo de monitoreo. El módulo de monitoreo detecta la cantidad de corriente consumida por la lámpara para determinar si el<br />
            filamento de la bombilla está intacto o fundido. Si la lámpara funciona correctamente, se encenderá la figura correspondiente en la<br />
             pantalla Touch. De lo contrario, la figura correspondiente comenzará a parpadear y sonará el buzzer para indicar una falla. El usuario<br />
              puede presionar el botón de <b>MUTE</b> para reconocer la falla y silenciar el Buzzer. El LED seguirá parpadeando para indicar una falla<br /> hasta que la lámpara se reemplace o se apague.
        </p>
      </div>
      <div className="imagen-portada-luz">
        <img src={luz_portada} alt="luz de control buque" className="image-style" />
      </div>
    </div>
    </>
  )
}

export default Luces
